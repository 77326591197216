import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const style = {
  display: "flex",
  justifyContent: "center",
};

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div style={style}>
      <h1>ページが見つかりません :(</h1>
    </div>
  </Layout>
)

export default NotFoundPage
